<template>
  <div class="contact">
    <section class="hero is-medium contact-img">
      <div class="hero-body">
        <div class="container">
          <h1 class="contact-title">{{ $t('contact.title') }}</h1>
        </div>
      </div>
    </section>

    <div class="location">
      <div class="container">
        <h5 class="header-title">{{ $t('contact.companyLocation') }}</h5>
        <div class="columns">
          <div class="column">
            <h4 class="content-title">{{ $t('about.taiwan') }}</h4>
            <p class="content-txt" v-html="$t('about.address1')"></p>
            <h4 class="content-title">{{ $t('about.taiwan_en') }}</h4>
            <p class="content-txt" v-html="$t('about.address1_en')"></p>
            <h4 class="content-title">{{ $t('about.china') }}</h4>
            <p class="content-txt" v-html="$t('about.address2')"></p>
            <h4 class="content-title">{{ $t('about.china_en') }}</h4>
            <p class="content-txt" v-html="$t('about.address2_en')"></p>
          </div>
          <div class="column">
            <ContactForm/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  components: {
    ContactForm: () => import('@/components/ContactForm.vue'),
  },
}
</script>

<style lang="sass" scoped>
.contact
  max-width: 100%
  .contact-img
    min-height: 470px
    background-image: url('../images/img_contactus_main.png')
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
  .hero-body
    .container
      .contact-title
        color: #000000
  .header-title
    margin-bottom: 50px
  .content-title
    color: #005cb9
    margin-bottom: 10px
  .location
    margin: 80px auto 160px
    .content-txt
      margin-bottom: 30px

@media (max-width: 768px)
  .contact
    .columns
      margin: 0
    .contact-img
      background-position: -880px center
      .hero-body
        display: flex
        align-items: center
    .location
      padding: 0
      overflow: hidden
      .container
        padding: 0
      .column:nth-child(1)
        padding: 2rem
      .column:nth-child(2)
        padding: 0
      .header-title
        padding: 0 2rem
</style>
